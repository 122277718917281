
























































































import MNotificationVue from "@/mixins/MNotification.vue";
import { Messages } from "@/models/enums/messages.enum";
import { DataResponseCalendar } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataRequestCLosePeriod,
  RequestUpdateClosePeriod,
} from "@/models/interface/settings.interface";
import { assetsServices } from "@/services/assets.service";
import { settingsServices } from "@/services/settings.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Vue, Component } from "vue-property-decorator";

@Component({
  mixins: [
    MNotificationVue,
  ]
})
export default class ClosePeriode extends Vue {
  loadingCalendar = false as boolean;
  dataCalendar = [] as DataResponseCalendar[];
  loadingOpen = false as boolean;
  loadingClose = false as boolean;
  form!: WrappedFormUtils;
  disabled = {
    close: false as boolean,
  };
  dataDetails = "" as any;
  formRules = {
    period: {
      label: "lbl_period_ar",
      name: "period",
      placeholder: "lbl_choose",
      decorator: [
        "period",
        {
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "",
      decorator: [
        "status",
      ],
    },
  };

  periodStatus = "";

  beforeCreate(): void {
    this.form = this.$form.createForm(this, {
      name: "closePeriod",
    });
  }
  mounted(): void {
  this.getListCalendar("");
  }

  get getPeriodStatus(): string {
    return this.form.getFieldValue("status");
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  close(): void {
    const dataPush = [] as DataRequestCLosePeriod[];
      const dataObj = {
        calendarClosePeriodId: this.dataDetails.id,
        closePeriod: true,
      };
      dataPush.push(dataObj);
    const dataPost = {
      requestBody: dataPush,
    } as RequestUpdateClosePeriod;
    this.loadingClose = true;
    settingsServices
      .updateCalendarClosePeriod(dataPost)
      .then(() => {
        this.showNotifSuccess("notif_update_success");
        this.getListCalendar("");
        this.form.resetFields();
      })
      .catch((e) => {
        this.isFormSubmitted = false;
        this.showNotifError("notif_update_fail");
      })
      .finally(() => (this.loadingClose = false));
  }
  handleCancel() {
    this.form.resetFields();
    this.$router.go(-1);
  }
  changeCalendar(value) {
    if (value) {
      const month = this.dataCalendar.find((item) => item.month === value);
      const calendar = month?.calendarClosePeriods;
      const armodule = calendar?.find(x => x.modulName === "AR");
      this.periodStatus = armodule?.closePeriod ? "lbl_close" : "lbl_open";
      // this.form.setFieldsValue({
      //   status: !this.dataCalendar
      //     .find((item) => item.month == value)
      //     ?.calendarClosePeriods.find((data) => data.modulName == "AR")
      //     ?.closePeriod
      //     ? "lbl_open"
      //     : "lbl_close",
      // });
      this.dataDetails = this.dataCalendar
          .find((item) => item.month == value)
          ?.calendarClosePeriods.find((data) => data.modulName == "AR");
      if (
        !this.dataCalendar
          .find((item) => item.month == value)
          ?.calendarClosePeriods.find((data) => data.modulName == "AR")
          ?.closePeriod
      )
        this.disabled.close = false;
      else this.disabled.close = true;
    }
  }
  getListCalendar(valueSearch) {
    let params = {
      page: 0,
      limit: 10,
    } as RequestQueryParamsModel;
    if (valueSearch) params.search = `month~*${valueSearch}*`;
    this.loadingCalendar = true;
    let dataPeriode = [] as any;
    this.dataCalendar = [];
    assetsServices
      .listCalendar(params)
      .then((data) => {
        data.data.forEach((element) => {
          if (!element.month.includes("Adj")) dataPeriode.push(element);
        });
        this.dataCalendar = dataPeriode;
      })
      .finally(() => (this.loadingCalendar = false));
  }
  get formItemLayout() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
  }
}
